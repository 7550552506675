import React from "react";
import { Page, Box, Heading } from "grommet";
import PriceEsimtation from "../components/PriceEstimation";

const ProjectCosting = ({ location }) => {
  return (
    <Page kind="narrow">
      <PriceEsimtation />
    </Page>
  );
};

export default ProjectCosting;
