import React, { useState, useContext } from "react";
import { Box, Button, DateInput } from "grommet";
import { API, graphqlOperation } from "aws-amplify";
import { useForm, Controller } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faArrowTurnRight,
} from "@fortawesome/free-solid-svg-icons";
import { Grommet } from "grommet";
import { useMediaQuery } from "react-responsive";
import { Link, navigate } from "gatsby";
import { triggerNotification } from "../../graphql/mutations";
import "./index.css";

const companyData = [
  {
    name: "Main Contractor",
    image: "/icons/maincon.svg",
  },
  {
    name: "Subcontractor",
    image: "/icons/subcon.svg",
  },
  {
    name: "Consultant",
    image: "/icons/owner.svg",
  },
  {
    name: "Government",
    image: "/icons/government.svg",
  },
];

const projectDuration = [
  {
    name: "< 1 Year",
    value: "1-year",
  },
  {
    name: "< 2 Years",
    value: "2-year",
  },
  {
    name: "< 3 Years",
    value: "3-year",
  },
  {
    name: "> 4 Years",
    value: "4-year",
  },
];

const priorityData = [
  {
    id: 0,
    name: "Equipping my site teams with mobile tools to be more efficient",
  },
  {
    id: 1,
    name: "Improving site to office communication",
  },
  {
    id: 2,
    name: "Simplifying daily reporting",
  },
  {
    id: 3,
    name: "Centralizing all project documents",
  },
  {
    id: 4,
    name: "Tracking time and materials installed",
  },
  {
    id: 5,
    name: "Getting insight into labour productivity",
  },
  {
    id: 6,
    name: "Understanding the financial impact of labour and production",
  },
  {
    id: 7,
    name: "Streamlining the change management process",
  },
  {
    id: 8,
    name: "Gain financial visibility",
  },
];

const productData = [
  {
    image:
      "https://plus.unsplash.com/premium_photo-1675404521308-2ceb136f3908?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1632&q=80",
    heading: "BIM Common Data Environment",
    subHeading:
      "Syncro Control is an Enterprise-Standard Data Management Solution for Infrastructure Projects",
    description:
      "The use of a Common Data Environment (CDE) streamlines collaboration, enhances communication, and ensures project stakeholders have access to the latest information.",
    href: "/idd/5-bim-documentation/",
  },
  {
    image:
      "https://images.unsplash.com/photo-1460925895917-afdab827c52f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1115&q=80",
    heading: "Track Progress with RFIs",
    subHeading:
      "Construction Project Management: Track Project Progress with Digital RFIs in Syncro",
    description:
      "RFIs are a critical part of construction projects as they help to clarify project requirements and specifications, resolve design issues, and ensure that the project is completed according to the client's expectations.",
    href: "/idd/1-digital-rfi/",
  },
  {
    image:
      "https://images.unsplash.com/photo-1538688273852-e29027c0c176?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fGRhdGF8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
    heading: "Enforce Contract Compliance",
    subHeading:
      "Syncro, a versatile project management tool, offers a range of features that can be effectively utilized to ensure contractual compliance by subcontractors.",
    description:
      "By leveraging Syncro's capabilities, project managers can streamline their oversight processes, improve communication, and hold subcontractors accountable for meeting their contractual obligations.",
    href: "/idd/10-digital-qaqc/",
  },
  {
    image:
      "https://images.unsplash.com/photo-1588600878108-578307a3cc9d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1476&q=80",
    heading: "4D Construction Scheduling",
    subHeading:
      "Build Compelling Construction Sequences with Syncro 4D",
    description:
      "Bring your construction projects to life with VR-quality sequences that impress your stakeholders. Experience a new level of project visualization and collaboration with our construction sequences.",
    href: "/software/syncro-4d-bim-construction-scheduling/",
  },
];

const PriceEstimation = () => {
  const {
    formState: { errors },
    control,
    handleSubmit,
    watch,
    register,
    getValues,
  } = useForm();
  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState({});
  const isTablet = useMediaQuery({
    query: "(max-width: 980px)",
  });

  async function onSubmit() {
    let { fullName, email, phone, company, requirements } =
      getValues();
    let input = {
      ...getValues(),
      ...formData,
    };
    if (step < 2) setStep(step + 1);
    // Only execute submission logic when final step is reached
    if (step !== 2) return;
    navigate("/#bim-packages");
  }

  async function handleNotification(input: Object) {
    try {
      await API.graphql(
        graphqlOperation(triggerNotification, { input: input })
      );
      console.log("form submission success");
    } catch (err) {
      console.log("failed to tigger notificaiton lambda", err);
    }
  }

  const handleCompnayTypeChange = (key, value) => {
    setFormData({ ...formData, [key]: value });
  };

  const handleProjectStart = (selection) => {
    setFormData({ ...formData, projectStart: selection.value });
  };

  const handleProjectEnd = (selection) => {
    setFormData({ ...formData, projectEnd: selection.value });
  };

  const handleCalculation = () => {
    const annualValue = () => {
      if (formData.activeCompany === "Subcontractor") {
        return 1 * 4000;
      } else if (formData.activeCompany === "Consultant") {
        return 2 * 4000;
      } else {
        return 3 * 4000;
      }
    };

    let { projectStart, projectEnd } = formData;

    let start = new Date(projectStart);
    let end = new Date(projectEnd);

    let startYear = start.getFullYear();
    let startMonth = start.getMonth();

    let endYear = end.getFullYear();
    let endMonth = end.getMonth();
    let duration =
      (endYear - startYear) * 12 + (endMonth - startMonth);

    let totalValue = annualValue() * duration;

    console.log("totalValue", totalValue, annualValue);

    return new Intl.NumberFormat("en-SG", {
      style: "currency",
      currency: "SGD",
    }).format(totalValue);
  };

  const getNextButtonDisabledStatus = () => {
    if (step === 0 && formData?.activeCompany) {
      return false;
    }
    if (
      step === 1 &&
      formData?.projectStart &&
      formData?.projectEnd
    ) {
      return false;
    }

    if (step === 2 && formData?.projectDuration) {
      return false;
    }

    if (step === 3) {
      return true;
    }
    console.log("getNextButtonStatus true-", formData?.activeCompany);
    return true;
  };

  const handleBack = () => {
    if (step !== 0) setStep(step - 1);
  };

  console.log("formData", formData);

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="price_estimation">
      <Box className="wrapper" pad="large">
        <Box className="question_header">
          {step < 4 && <h2>Need a Quote?</h2>}
          {step === 4 && <h2>We're working on it.</h2>}

          {step < 4 && (
            <p>
              Tap on our network of 400 BIM professionals, robust
              technology infrastructure, and established BIM workflows
              to enable integrated digital project delivery.
            </p>
          )}
          {step === 4 && (
            <p>
              Someone will contact you within 3 working days to give
              you a customised price quote. In the meantime, check out
              some of these links if you want to learn more about us!
            </p>
          )}
          {step == 0 && (
            <h5>What type of company do you work for?</h5>
          )}
          {step === 1 && <h5>What is the project duration?</h5>}
          {step === 2 && (
            <Box>
              <h5>Esimated BIM project cost for your company</h5>
              <Link className="text-s" to="/#bim-packages">
                Package Details
              </Link>
            </Box>
          )}
        </Box>
        <Box className="questionnaire-answers icon-select">
          <Box className="answers-row">
            {step === 0 &&
              companyData.map((company, index) => (
                <Box
                  className={
                    isTablet
                      ? "questionnaire-answer-wrapper w-50"
                      : "questionnaire-answer-wrapper col-5"
                  }
                  key={index}
                >
                  <Box
                    className={
                      formData?.activeCompany === company.name
                        ? "questionnaire-answer active"
                        : "questionnaire-answer"
                    }
                    onClick={() =>
                      handleCompnayTypeChange(
                        "activeCompany",
                        company.name
                      )
                    }
                  >
                    <img
                      className="questionnaire-answer-icon"
                      src={company.image}
                      alt={company.name}
                    />
                    <p className="questionnaire-answer-text">
                      {company.name}
                    </p>
                  </Box>
                </Box>
              ))}
            {step === 1 && (
              <div className="flex flex-row gap-3">
                <input
                  type="date"
                  placeholder="Start Date"
                  value={formData.projectStart}
                  onChange={(e) =>
                    handleProjectStart({ value: e.target.value })
                  }
                />
                <input
                  type="date"
                  placeholder="End Date"
                  value={formData.projectEnd}
                  onChange={(e) =>
                    handleProjectEnd({ value: e.target.value })
                  }
                />
              </div>
            )}

            {step === 2 && (
              <Box className="questionnaire-answer-icon">
                <h2>{handleCalculation()}</h2>
              </Box>
            )}
          </Box>
        </Box>

        {step < 3 && (
          <Box>
            <Box className="questionnaire-nav">
              {step > 0 && (
                <Button className="btn btn-grey" onClick={handleBack}>
                  <FontAwesomeIcon icon={faArrowLeft} color="#000" />
                  &nbsp;&nbsp;Back
                </Button>
              )}
              {step < 2 && (
                <Button
                  disabled={getNextButtonDisabledStatus()}
                  className={
                    !getNextButtonDisabledStatus()
                      ? "btn btn-orange"
                      : "btn btn-orange disabled"
                  }
                  type="submit"
                >
                  Next&nbsp;&nbsp;
                  <FontAwesomeIcon icon={faArrowRight} color="#000" />
                </Button>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </form>
  );
};

export default PriceEstimation;
